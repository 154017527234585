import { useEffect } from "react";

import { compile, match } from "path-to-regexp";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import type { Middleware, SWRHook } from "swr";

import {
  API_ENDPOINT_GET_SPACE_BY_ID,
  API_ENDPOINT_GET_SPACE_BY_URL,
  INVITATION_TYPES,
  ROUTER_PATH_SPACE,
  ROUTER_PATH_SPACE_NODE,
  ROUTER_PATH_SPACE_WITH_PRESENTATION,
} from "@/src/constants/AppConstant";
import type { GetSpaceByUrlRequest, GetSpaceByUrlResponse } from "@/src/domains/content/types/ContentAPITypes";
import { selectors as inviteSelectors } from "@/src/domains/user/components/Invite/state";
import useSWRFetch from "@/src/hooks/useSWRFetch";
import { useSpaceUrl, useSpaceUrlParameter } from "@/src/pages/SpaceLandingPage/utils/SpaceUrlParameterHelper";
import { getSpaceIdBySpaceUrlSelector, setSpaceData } from "@/src/stores/space/spaceReducer";
import { userIdSelector } from "@/src/stores/user/selectors";
import { safeDecodeURI } from "@/src/utils/helpers/UrlHelper";

export const LOAD_SPACE_INFO_RETRY_COUNT = 3;

export const handleObsoleteSpaceRedirection = (newUrl: string) => {
  const pathName = window.location.pathname;
  let newPath = "";
  const isMatchSpace = match(ROUTER_PATH_SPACE, { decode: safeDecodeURI });
  const isMatchSpaceWithPresentation = match(ROUTER_PATH_SPACE_WITH_PRESENTATION, { decode: safeDecodeURI });
  const isMatchSpaceNode = match(ROUTER_PATH_SPACE_NODE, { decode: safeDecodeURI });

  const isMatchSpacePathData = isMatchSpace(pathName);
  const isMatchSpaceWithPresentationData = isMatchSpaceWithPresentation(pathName);
  const isMatchSpaceNodeData = isMatchSpaceNode(pathName);

  if (isMatchSpacePathData) {
    const toPath = compile(ROUTER_PATH_SPACE);
    newPath = toPath({ spaceId: newUrl });
  } else if (isMatchSpaceWithPresentationData) {
    const toPath = compile(ROUTER_PATH_SPACE_WITH_PRESENTATION);
    newPath = toPath({ ...isMatchSpaceWithPresentationData.params, spaceId: newUrl });
  } else if (isMatchSpaceNodeData) {
    const toPath = compile(ROUTER_PATH_SPACE_NODE);
    newPath = toPath({ ...isMatchSpaceNodeData.params, spaceId: newUrl });
  }

  if (newPath) {
    return newPath;
  }
  return false;
};

export const getSpaceCacheKey = (options: { isSearchPage?: boolean; spaceUrl?: string; userId?: number }) => {
  const { isSearchPage, spaceUrl, userId } = options;

  // If it is the search page, no need to compute cache keys
  if (isSearchPage) {
    return null;
  }

  // Ensure that the function received either spaceUrl or userId or both
  if (!spaceUrl && !userId) {
    return null;
  }

  // No point in computing cache keys if spaceUrl is empty
  if (!spaceUrl || spaceUrl.length === 0) {
    return null;
  }

  const spaceKey = [API_ENDPOINT_GET_SPACE_BY_URL, spaceUrl];

  /**
   * Add userId to the cache key if provided and the initial space key is not empty
   * This is because guest users can also access space by URL and we want to distinguish between different users
   */
  if (userId && spaceKey.length > 0) {
    spaceKey.push(String(userId));
  }

  if (spaceKey.length === 0) {
    return null;
  }

  return spaceKey;
};

/** Middleware for spaceInfo SWR */
export const spaceInfoSWRMiddleware: Middleware = (useSWRNext: SWRHook) => {
  return (key, fetcher, config) => {
    const spaceUrl = useSpaceUrl();
    const history = useHistory();
    const url = key && Array.isArray(key) ? key[0] : "";
    const swr = useSWRNext(key, fetcher, config);

    useEffect(() => {
      if (swr.data !== undefined) {
        // when the current provided spaceUrl is stale, redirect to newer one
        const data = swr.data as unknown as GetSpaceByUrlResponse;
        let newUrl;
        if (data && data.spaceUrlInfo?.isSpaceUrlObsolete) {
          newUrl = data.spaceUrlInfo.newSpaceUrl;
        } else if (spaceUrl !== data.contentSpace.spaceUrl) {
          newUrl = data.contentSpace.spaceUrl;
        }

        if (newUrl) {
          const newPath = handleObsoleteSpaceRedirection(newUrl);
          if (newPath) {
            history.replace({ pathname: newPath, search: location.search });
          }
        }
      }
    }, [swr.data, spaceUrl, url, history]);

    return swr;
  };
};

export const sanitizedSpaceUrl = (url: string) => {
  // To match BE encoding logic to avoid miss match that return 404
  // Each browser handle url auto decode differently. e.g chrome auto decode but not safari, that can causing different value with value that stored on BE that will return 404
  // So always decode and encode the url. Decode needed to avoid double encoding.
  const decodedSpaceUrl = safeDecodeURI(url);
  return encodeURI(decodedSpaceUrl);
};

interface GetSpaceByURLOptions {
  retryOnFailed?: boolean;
  redirectOnMismatch?: boolean;
  revalidateIfStale?: boolean;
}
/**
 * Returns the space data for the given space url
 * @param spaceUrl The space url to get the space data for
 * @returns GetSpaceByUrlResponse
 */
export default function useGetSpaceByURL(spaceUrl?: string, options?: GetSpaceByURLOptions) {
  const userId = useSelector(userIdSelector);

  const dispatch = useDispatch();
  const invitationToken = useSelector(inviteSelectors.invitationTokenSelector, shallowEqual);

  const spaceId = useSelector(getSpaceIdBySpaceUrlSelector(spaceUrl));

  const cacheKey = getSpaceCacheKey({
    spaceUrl,
    userId,
  });

  const payload: {
    spaceUrl?: string;
    spaceId?: number;
    token?: string;
  } = {
    spaceUrl: spaceUrl && sanitizedSpaceUrl(spaceUrl),
    ...(invitationToken?.spaceUrl === spaceUrl && {
      token: invitationToken.type === INVITATION_TYPES.LINK ? invitationToken.value : invitationToken.hash,
    }),
  };

  if (spaceId) {
    payload.spaceId = spaceId;
    payload.spaceUrl = undefined;
  }

  return useSWRFetch<GetSpaceByUrlResponse, Partial<GetSpaceByUrlRequest>>({
    url: spaceId ? API_ENDPOINT_GET_SPACE_BY_ID : API_ENDPOINT_GET_SPACE_BY_URL,
    cacheKey: spaceUrl && cacheKey,
    payload: payload,
    swrOptions: {
      revalidateIfStale: options?.revalidateIfStale,
      errorRetryCount: LOAD_SPACE_INFO_RETRY_COUNT,
      shouldRetryOnError: options?.retryOnFailed,
      use: options?.redirectOnMismatch ? [spaceInfoSWRMiddleware] : [],
      onSuccess: (data: GetSpaceByUrlResponse) => {
        if (data?.contentSpace?.spaceUrl) {
          dispatch(
            setSpaceData({
              spaceData: data,
              source: "SPACE_BY_URL",
            }),
          );
        }
      },
    },
  });
}

export function useGetCurrentSpaceByURL(options?: GetSpaceByURLOptions) {
  const spaceUrl = useSpaceUrlParameter();

  return useGetSpaceByURL(spaceUrl, options);
}
